<template>
    <v-table-row :class="classes">
        <v-table-data colspan="100%">
            <v-text>
                {{ message }}
            </v-text>
        </v-table-data>
    </v-table-row>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            required: false,
            default: () => "No results found.",
        },
    },
    data() {
        return {
            baseClasses: "",
        };
    },
    computed: {
        classes() {
            return `table-row-no-results ${this.baseClasses}`;
        },
    },
};
</script>
